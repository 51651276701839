import { Decimal } from 'decimal.js'
import { sanitizeNumber } from '../../../../components/helper/helper'

export const calculateSubTotalPrice = (
  services: Array<{
    parts: Array<{ price_per_unit: number; quantity: number }>
    labor_hours: number | string
    labor_rate?: number
  }>,
  laborRate: number = 0
): number => {
  return services.reduce((total, service) => {
    const partsTotal = service.parts.reduce((acc, part) => {
      const partTotal = new Decimal(sanitizeNumber(part.price_per_unit)).mul(part.quantity)
      return acc.plus(partTotal)
    }, new Decimal(0))

    const laborTotal = new Decimal(sanitizeNumber(service.labor_hours)).mul(
      service.labor_rate || laborRate
    )

    const servicesTotal = partsTotal.plus(laborTotal).toDecimalPlaces(2)

    return total + servicesTotal.toNumber()
  }, 0)
}
